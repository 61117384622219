import React from 'react';
import { graphql, Link, StaticQuery, withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/zh';
import ProfessionalismImage from '../images/professionalism.jpg';
import SolutionsImage from '../images/innovative-solutions.jpg';

const HomePage = (props) => {
  const features = props.data.allFeaturesJson.edges;

  return (
    <Layout bodyClass="page-home" location={props.location}>
      <SEO title="Home"/>
      <Helmet>
        <meta
          name="Massam Corporate Consulting"
          content="Small Business Theme. Multiple content types using Markdown and JSON sources. Responsive design and SCSS. This is a beautiful and artfully designed starting theme."
        />
      </Helmet>
      <div className="intro">
        <div className="intro-container">
          <div className="intro-row">
            <div className="intro-content col-12 col-md-6">
              <h1>邁升企業顧問</h1>
              <p>憑藉我們在各個行業的全球廣泛經驗，為您的業務提供解決方案。我們了解在這個困難時期各個行業所面臨的挑戰。除了協助您處理賬簿和會計記錄，我們還擅長於業務諮詢、企業數碼化轉型、管理會計和分析。</p>
              <a href="/zh/about" className="button">
                關於我們
              </a>
            </div>
            <div className="intro-hero col-md-6"/>
          </div>
        </div>
      </div>

      <div className="p-4 intro-about">
        <h3 className="text-center">
          邁升企業顧問
        </h3>
        <h4 className="text-center">致力超越您所期望</h4>
      </div>

      <div className="container pt-6 pb-md-6 professionalism">
        <div className="row">
          <div className="col-12 col-md-6 content pl-md-4 text-center text-md-left order-2 order-md-1">
            <h4 className="title-3 text-dark">專業顧問</h4>
            <p>藉著我們的專業知識和廣泛的專業網絡，我們可以為您的企業解決問題。我們的合作夥伴關係將使您能專注於自己的優勢，以持續發揚業務繼往開來的成功。</p>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <img src={ProfessionalismImage} alt=""/>
          </div>
        </div>
      </div>

      <div className="container pt-2 pb-md-2 professionalism">
        <div className="row">
          <div className="col-12 col-md-6">
            <img src={SolutionsImage} alt=""/>
          </div>
          <div className="col-12 col-md-6 content pr-md-4 text-center text-md-left">
            <h4 className="title-3 text-dark">創新解決方案</h4>
            <p>作為年輕而富有朝氣的企業顧問，我們為您提供度身制定的創新解決方案。所得的解決方案往往都會根據我們對您的業務的全面了解來構建制定。</p>
          </div>
        </div>
      </div>


      <div className="container pt-8 pt-md-2">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">我們的策略</h2>
          </div>
          <div className="col-12 col-md-6 mb-1">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>切合地提問</h2>
                <p>我們致力由更有切合性的提問出发，透過我們提出的問題，為當前最迫切的挑戰提出質疑、帶來啟发並推出嶄新的解決方案。我們相信合適既提問會帶來合適的解決方案。</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-1">
            <div className="card service service-teaser">
              <div className="card-content">
                <h2>
                  高效的互動
                </h2>
                <p>我們相信每次咨詢的機會以及與客戶或同事的每次互動可以幫助我們的客戶更有效地運營、管理風險、促進增長及建立信心。</p>
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/zh/services">
              我們的服務
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">我們的聯盟</h2>
          </div>
          {features.map(edge => (
            <div key={edge.node.id} className="col-12 col-lg-4 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.node.image)} alt=""/>
                  </div>
                )}
                <h2 className="feature-title">{edge.node.title_zh}</h2>
                <div className="feature-content">{edge.node.description_zh}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const Home = (props) => (
  <StaticQuery
    query={graphql`
          query HomeQuery {
            allFeaturesJson {
              edges {
                node {
                    id
                    title_zh
                    description_zh
                    image
              }
            }
          }
         }
      `}
    render={(data) => <HomePage {...props} data={data}/>}
  />
);

export default Home;
